<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="600"
      persistent
    >
      <v-card class="px-8 pt-8 pb-12">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold">
              Detail Perencanaan Program Kerja
            </span>
            <span
              style="cursor: pointer"
              @click="close"
            >
              X
            </span>
          </v-col>
        </v-row>

        <template v-if="dataModal">
          <v-row class="mt-4">
            <v-col
              class="pt-4 txt16-gray50"
              cols="12"
              sm="4"
            >
              Entitas
            </v-col>
            <v-col
              class="txt16-gray50-bold"
              cols="12"
              sm="8"
            >
              {{ dataModal.entity_main.name }}
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col
              class="pt-4 txt16-gray50"
              cols="12"
              sm="4"
            >
              Sub Entitas
            </v-col>
            <v-col
              class="d-flex flex-column txt16-gray50-bold"
              cols="12"
              sm="8"
            >
              <div
                v-for="(v, i) in dataModal.sub_entity"
                :key="'sub-entity-' + i"
              >
                {{ v.name }}
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col
              class="pt-4 txt16-gray50"
              cols="12"
              sm="4"
            >
              Bidang
            </v-col>
            <v-col
              class="txt16-gray50-bold"
              cols="12"
              sm="8"
            >
              {{ dataModal.sector }}
            </v-col>
          </v-row>

          <v-row class="mt-2">
            <v-col
              class="txt16-gray50"
              cols="12"
              sm="4"
            >
              Judul Program Kerja
            </v-col>
            <v-col
              class="txt16-gray50-bold"
              cols="12"
              sm="8"
            >
              {{ dataModal.title }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="txt16-gray50"
              cols="12"
              sm="4"
            >
              Tanggal Rencana
            </v-col>
            <v-col
              class="txt16-gray50-bold"
              cols="12"
              sm="8"
            >
              {{ dataModal.plan_date }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="txt16-gray50"
              cols="12"
              sm="4"
            >
              Keterangan
            </v-col>

            <v-col
              class="txt16-gray50-bold"
              cols="12"
              sm="8"
              style="flex-direction: column"
            >
              {{ dataModal.content }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="d-flex align-center txt16-gray50"
              cols="12"
              sm="4"
            >
              Lampiran
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <div
                v-for="(v, i) in dataModal.list_file"
                :key="'file-' + i"
                class="txt16-gray50-bold"
              >
                {{ v.file_name }}
                <v-icon
                  class="mx-1"
                  size="16px"
                  style="cursor: pointer"
                  @click="previewURL(v)"
                >
                  mdi-eye
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      dataModal: {
        type: Object,
        default: null,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      modal: false,
      modal2: false,
      judul: '',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      keterangan: '',
      uploadKey: 0,
      photoHash: null,
      isLoading: {
        1: false,
      },
      uploadedFile: {
        1: null,
      },
      sector: [],
      vSector: null,
    }),

    computed: {},

    watch: {},

    created () {
      this.initialize()
    },

    methods: {
      close () {
        this.$emit('close')
      },

      uploadFile (key) {
        this.uploadKey = key
        document.getElementById('upload-file').click()
      },

      previewURL (p) {
        // console.log(p)
        const url = []
        url.push(p.url)
        this.$viewerApi({ images: url })
      },

      confirm (p) {
        // console.log(p)
        if (p === 0) {
          this.modal = false
        } else {
          this.modal = false
          this.submit()
        }
      },

      initialize () {
        axios.post('/v1/general/get-company-sector').then((res) => {
          if (res.data.status === 200) {
            this.sector = res.data.data.sector.list
          }
        })
      },

      upload (e) {
        this.isLoading[this.uploadKey] = true
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('file', e)
        requestBody.append('dest', 'member_photo')

        axios.post(baseCDNurl + '/v1/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            this.isLoading[this.uploadKey] = false
            this.uploadedFile[this.uploadKey] = res.data.data
            this.photoHash = res.data.data.tmp_file_hash
          }
        })
          .catch((e) => {
            this.isLoading[this.uploadKey] = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      submit () {
        const files = []
        files.push(this.photoHash)
        const requestBody = {
          sector: this.vSector,
          title: this.judul,
          plan_date: this.date,
          note: this.keterangan,
          list_file: files,
        }

        axios.post('/v1/admin/working-plan/createe', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.judul = ''
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.keterangan = ''
            this.photoHash = null
            this.uploadedFile = { 1: null }
            this.$emit('init')
            this.$toast.success(res.data.message)
            this.close()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.symbol-required {
  color: $red-1;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
