<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="400"
      persistent
    >
      <v-card class="px-8 py-8">
        <div class="d-flex justify-center txt16-gray50-bold">
          {{ text }}
        </div>
        <div class="d-flex justify-center mt-4">
          <v-btn
            color="#2E976C"
            class="mx-2 text-capitalize"
            style="font-weight: bold; color: white"
            @click="submit(1)"
          >
            Yakin
          </v-btn>
          <v-btn
            color="#CCCCCC"
            class="mx-2 text-capitalize"
            style="font-weight: bold; color: white"
            @click="submit(0)"
          >
            Batal
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: 'Apakah anda yakin?',
      },
    },

    data: () => ({}),

    computed: {},

    watch: {},

    created () {},

    methods: {
      submit (p) {
        this.$emit('submit', p)
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
