<template>
  <v-container fluid>
    <div class="btn-container" @click="uploadBtnClick">
      <div v-if="isLoading">
        <v-progress-circular indeterminate color="#36AC87" />
      </div>

      <div v-else>
        <template v-if="multiple">
          <template v-if="uploadFiles.length > 0">
            <!-- <span> -->
            <!-- {{ uploadFiles[0].file_name }} -->
            <!-- File(s) berhasil diupload -->
            <!-- </span> -->
            <v-icon class="px-2" color="#CCCCCC">
              mdi-cloud-upload-outline
            </v-icon>
            <span class="txt16-gray80">
              {{ text }}
            </span>
          </template>
          <template v-else>
            <v-icon class="px-2" color="#CCCCCC">
              mdi-cloud-upload-outline
            </v-icon>
            <span class="txt16-gray80">
              {{ text }}
            </span>
          </template>
        </template>
        <template v-else>
          <!-- <template v-if="uploadFile.length == 0"> -->
          <template v-if="Object.keys(uploadFile).length == 0">
            <v-icon class="px-2" color="#CCCCCC">
              mdi-cloud-upload-outline
            </v-icon>
            <span class="txt16-gray80">
              {{ text }}
            </span>
          </template>
          <template v-else>
            <span>
              {{ uploadFile.file_name_ori }}
            </span>
          </template>
        </template>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "UploadButton",

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    uploadFile: {
      type: Object,
      default: null,
    },
    uploadFiles: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: "Masukkan file Dokumen atau Gambar",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),

  methods: {
    uploadBtnClick() {
      this.$emit("uploadButtonClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 51px;
  background: $gray-98;
  border: 1px dashed #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.txt16-gray80 {
  @extend .p-1;
  color: $gray-80;
}
</style>
