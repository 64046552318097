<template>
  <div>
    <v-dialog v-model="show" max-width="600" persistent>
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold">
              Edit Perencanaan Program Kerja
            </span>
            <span style="cursor: pointer" @click="close"> X </span>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Entitas <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-combobox
              v-model="vEntity"
              :items="entityMain"
              color="#36AC87"
              item-text="name"
              item-value="ent_hash"
              placeholder="Pilih Entitas"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row v-for="v in subEntityLength" :key="'entity-' + v" class="mt-n6">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            <span v-if="v === 1"> Kolaborasi </span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-combobox
              :id="'sub-entity-' + v"
              v-model="vSubEntity[v - 1]"
              :items="entity"
              color="#36AC87"
              item-text="name"
              item-value="ent_hash"
              placeholder="Pilih Kolaborasi"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-n6 mb-4">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4" />
          <v-col cols="12" sm="8">
            <v-btn
              class="text-capitalize"
              color="#36AC87"
              block
              outlined
              @click="
                vSubEntity.push('');
                subEntityLength += 1;
              "
            >
              Tambah Kolom
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Bidang <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-select
              v-model="vSector"
              :items="sector"
              color="#36AC87"
              item-text="dept_name"
              item-value="dept_hash"
              placeholder="Pilih Bidang"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col class="pt-4 txt16-gray50" cols="12" sm="4">
            Judul Program Kerja <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="judul"
              type="text"
              outlined
              autocomplete="off"
              dense
              color="#36AC87"
              placeholder="Masukkan program kerja"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n6">
          <v-col class="pt-6 txt16-gray50" cols="12" sm="4">
            Tanggal Rencana <span class="symbol-required">*</span>
          </v-col>
          <v-col cols="12" sm="8">
            <div data-app class="mt-2">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    readonly
                    color="#36AC87"
                    v-bind="attrs"
                    dense
                    outlined
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="date" scrollable color="#36AC87">
                  <v-spacer />
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-n4">
          <v-col class="txt16-gray50" cols="12" sm="4">
            Keterangan <span class="symbol-required">*</span>
          </v-col>

          <v-col class="d-flex" cols="12" sm="8" style="flex-direction: column">
            <v-textarea
              v-model="keterangan"
              rows="4"
              no-resize
              outlined
              color="#36AC87"
              placeholder="Masukkan Keterangan"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n8">
          <v-col class="d-flex align-center txt16-gray50" cols="12" sm="4">
            Lampiran
          </v-col>

          <v-col class="px-0" cols="12" sm="8">
            <upload-button
              id="lampiran"
              :is-loading="isLoading"
              :upload-files="uploadedFiles"
              multiple
              @uploadButtonClick="uploadFile"
            />
            <v-file-input
              id="upload-file"
              accept="image/*"
              style="display: none"
              multiple
              @change="upload"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span
              v-for="(v, i) in uploadedFiles"
              :key="'img-' + i"
              class="mx-3"
            >
              <v-badge
                bordered
                color="error"
                icon="mdi-close"
                overlap
                offset-x="8"
                offset-y="8"
                style="cursor: pointer"
                @click.native="deleteImage(i)"
              >
                <v-img
                  :src="v.file_path_thumb ? v.file_path_thumb : v.url_thumb"
                  width="200px"
                  height="200px"
                />
              </v-badge>
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="d-flex justify-end mt-4">
              <v-btn
                color="#2E976C"
                class="mx-2 text-capitalize"
                style="font-weight: bold; color: white"
                @click="modal = true"
              >
                Simpan
              </v-btn>
              <v-btn
                color="#CCCCCC"
                class="mx-2 text-capitalize"
                style="font-weight: bold; color: white"
                @click="close"
              >
                Batal
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <modal-konfirmasi :show="modal" @submit="confirm" />
  </div>
</template>

<script>
import UploadButton from "../komponen/UploadButton.vue";
import ModalKonfirmasi from "../komponen/modal/ModalKonfirmasi.vue";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    UploadButton,
    ModalKonfirmasi,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataModal: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    modal: false,
    modal2: false,
    judul: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    keterangan: "",
    uploadKey: 0,
    photoHash: null,
    isLoading: false,
    uploadedFile: {
      1: null,
    },
    sector: [],
    vSector: null,
    uploadedFiles: [],
    entityMain: [],
    entity: [],
    vEntity: null,
    subEntityLength: 3,
    subEntity: "",
    vSubEntity: [],
  }),

  computed: {},

  watch: {
    show: {
      immediate: true,
      handler() {
        this.mappingEditData();
      },
    },
  },

  created() {
    this.initialize();
    this.getEntityMain();
    this.getEntity();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    uploadFile(key) {
      this.uploadKey = key;
      document.getElementById("upload-file").click();
    },

    previewURL(p) {
      // console.log(p)
      const url = [];
      url.push(p.url);
      this.$viewerApi({ images: url });
    },

    deleteImage(p) {
      // console.log(p)
      this.uploadedFiles.splice(p, 1);
    },

    confirm(p) {
      // console.log(p)
      if (p === 0) {
        this.modal = false;
      } else {
        this.modal = false;
        this.submit();
      }
    },

    mappingEditData() {
      this.vEntity = this.dataModal.entity_main;
      this.vSector = this.dataModal.sector_hash;
      this.judul = this.dataModal.title;
      this.date = this.dataModal.plan_date;
      this.keterangan = this.dataModal.content;
      this.uploadedFiles = this.dataModal.list_file;
      this.dataModal.sub_entity.map((v) => {
        this.vSubEntity.push(v);
      });
    },

    initialize() {
      axios.post("/v1/general/get-department").then((res) => {
        if (res.data.status === 200) {
          this.sector = res.data.data.department.list;
        }
      });
    },

    getEntityMain() {
      axios.post("/v1/general/get-entity", {restrict: true}).then((res) => {
        if (res.data.status === 200) {
          this.entityMain = res.data.data.entity.list;
        }
      });
    },
    getEntity() {
      axios.post("/v1/general/get-entity").then((res) => {
        if (res.data.status === 200) {
          this.entity = res.data.data.entity.list;
        }
      });
    },

    async upload(e) {
      this.isLoading = true;
      const length = e.length;
      for (let i = 0; i < length; i++) {
        const res = await this.postUpload(e[i]);
        if (res === "NOK") {
          this.$toast.error("Upload file(s) failed");
          this.uploadedFiles = [];
          this.isLoading = false;
          return;
        }
      }
      this.isLoading = false;
    },

    postUpload(e) {
      // console.log(e)
      return new Promise((resolve) => {
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL;
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const requestBody = new FormData();
        requestBody.append("file", e);
        requestBody.append("dest", "member_photo");

        axios
          .post(baseCDNurl + "/v1/upload", requestBody, config)
          .then((res) => {
            if (res.data.status === 200) {
              this.uploadedFiles.push(res.data.data);
              resolve("OK");
            }
          })
          .catch((e) => {
            resolve("NOK");
            if (typeof e.response.data.error === "object") {
              this.$toast.error(Object.values(e.response.data.error)[0][0]);
            } else {
              this.$toast.error(e.response.data.error);
            }
          });
      });
    },

    submit() {
      const files = [];
      this.uploadedFiles.map((v) => {
        if (v.hash) files.push(v.hash);
        if (v.tmp_file_hash) files.push(v.tmp_file_hash);
      });

      const subEntity = [];
      this.vSubEntity.map((v) => {
        if (v !== "") subEntity.push(v.ent_hash);
      });

      const requestBody = {
        dept_hash: this.vSector,
        wp_hash: this.dataModal.hash,
        title: this.judul,
        plan_date: this.date,
        note: this.keterangan,
        list_file: files,
        ent_hash: this.vEntity.ent_hash,
        list_sub_ent: subEntity,
      };

      axios
        .post("/v1/admin/working-plan/update", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.judul = "";
            this.date = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10);
            this.keterangan = "";
            this.photoHash = null;
            this.uploadedFile = { 1: null };
            this.$emit("init");
            this.$toast.success(res.data.message);
            this.close();
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.symbol-required {
  color: $red-1;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
